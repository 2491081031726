// Your existing utility functions
export function multiply(a, b) {
    return a * b;
  }
  
  // Add new utility functions as needed
  export function add(a, b) {
    return a + b;
  }
  
  export function subtract(a, b) {
    return a - b;
  }
  